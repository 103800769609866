import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
  useContext,
} from "react";
import { useAuth } from "./AuthContext";
import { BASE_URL } from "../App";

const OrganizationContext = createContext();

export const useOrganization = () => useContext(OrganizationContext);

export const OrganizationProvider = ({ children }) => {
  const { user } = useAuth();
  const [touchpointLocations, setTouchpointLocations] = useState([]);

  return (
    <OrganizationContext.Provider
      value={{
        touchpointLocations,
      }}
    >
      {children}
    </OrganizationContext.Provider>
  );
};
