import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import { AccountsProvider } from "./context/AccountsContext";
import { CampaignProvider } from "./context/CampaignContext";
import { OpeningsProvider } from "./context/OpeningsContext";
import { OpportunityProvider } from "./context/OpportunityContext";
import { OrganizationProvider } from "./context/OrganizationContext";
import { MonitorProvider } from "./context/MonitorContext";
import { TouchpointProvider } from "./context/TouchpointContext";
import AccountDetail from "./AccountDetail";
import AccountList from "./AccountList";
import CreateAccountPage from "./CreateAccountPage";
import HomePage from "./HomePage";
import Inbox from "./Inbox";
import LoginPage from "./LoginPage";
import Nav from "./Nav";
import OpportunityList from "./OpportunityList";
import OpportunityViewer from "./OpportunityViewer";
import OrganizationSettings from "./OrganizationSettings";
import TouchpointEditor from "./TouchpointEditor";
import TouchpointList from "./TouchpointList";
import TouchpointDetail from "./TouchpointDetail";
import MonitorEditor from "./MonitorEditor";
import MonitorList from "./MonitorList";
import "./App.css";
import BG from "./assets/bg.png";
import MonitorDetail from "./MonitorDetail";

export const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const Layout = () => (
  <div className="App" style={{ backgroundImage: `url(${BG})` }}>
    <Nav />
    <div className="content">
      <main>
        <Outlet />
      </main>
    </div>
  </div>
);

const AuthenticatedRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" element={<Navigate to="/monitors" replace />} />
        {/* <Route path="/accounts">
          <Route index element={<AccountList />} />
          <Route path=":accountId" element={<AccountDetail />}>
            <Route index element={<Navigate to="issues" replace />} />
            <Route path="issues" element={<AccountDetail />}>
              <Route path=":issueId" element={<AccountDetail />} />
            </Route>
          </Route>
        </Route> */}

        <Route path="/monitors">
          <Route index element={<Navigate to="/monitors/all" replace />} />
          <Route path="all">
            <Route index element={<MonitorList />} />
            <Route path=":id" element={<MonitorDetail />} />
          </Route>
          {/* <Route path="shared">
            <Route index element={<MonitorList />} />
            <Route path=":id" element={<MonitorDetail />} />
          </Route> */}

          <Route path="create" element={<MonitorEditor />} />
        </Route>

        {/* <Route path="/inbox">
          <Route index element={<Inbox />} />
          <Route path=":openingId" element={<Inbox />} />
        </Route>
        <Route path="/opportunities">
          <Route index element={<OpportunityList />} />
          <Route path=":id">
            <Route path="view" element={<OpportunityViewer />} />
          </Route>
        </Route>
        <Route path="/touchpoints">
          <Route index element={<TouchpointList />} />
          <Route path="create" element={<TouchpointEditor />} />
          <Route path=":id">
            <Route index element={<TouchpointDetail />} />
            <Route path="edit" element={<TouchpointEditor />} />
          </Route>
        </Route>
        <Route path="/org-settings">
          <Route index element={<OrganizationSettings />} />
        </Route> */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
};

const AuthenticatedApp = () => {
  return (
    <OrganizationProvider>
      <AccountsProvider>
        <CampaignProvider>
          <TouchpointProvider>
            <OpportunityProvider>
              <OpeningsProvider>
                <MonitorProvider>
                  <AuthenticatedRoutes />
                </MonitorProvider>
              </OpeningsProvider>
            </OpportunityProvider>
          </TouchpointProvider>
        </CampaignProvider>
      </AccountsProvider>
    </OrganizationProvider>
  );
};

const PublicApp = () => (
  <Routes>
    <Route path="/login" element={<LoginPage />} />
    {/* <Route path="/register" element={<CreateAccountPage />} /> */}
    <Route path="*" element={<Navigate to="/login" />} />
  </Routes>
);

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <BrowserRouter>
      {isAuthenticated ? <AuthenticatedApp /> : <PublicApp />}
    </BrowserRouter>
  );
}

export default App;
