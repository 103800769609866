import React, { useEffect } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import "./TextEditor.css";

const TextEditor = ({
  content,
  onChange,
  placeholder,
  disabled = false,
  className = "",
}) => {
  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        // Disable all formatting except bold, italic, and lists
        heading: false,
        code: false,
        codeBlock: false,
        blockquote: false,
        horizontalRule: false,
        strike: false,
        hardBreak: false,
        // Keep only these enabled:
        bold: true,
        italic: true,
        bulletList: true,
        orderedList: true,
      }),
      Placeholder.configure({
        placeholder,
      }),
    ],
    content: content || "",
    editable: !disabled,
    editorProps: {
      attributes: {
        class: "prose prose-sm focus:outline-none",
      },
      handleKeyDown: (view, event) => {
        // Make Shift+Enter behave the same as Enter
        if (event.key === "Enter" && event.shiftKey) {
          editor.chain().focus().enter().run();
          return true;
        }
        return false;
      },
    },
    onUpdate: ({ editor }) => {
      onChange?.(editor.getHTML());
    },
  });

  useEffect(() => {
    if (editor && content !== editor.getHTML()) {
      editor.commands.setContent(content || "");
    }
  }, [editor, content]);

  useEffect(() => {
    if (editor) {
      editor.setEditable(!disabled);
    }
  }, [editor, disabled]);

  return (
    <div className={`text-editor ${disabled ? "disabled" : ""} ${className}`}>
      <EditorContent editor={editor} />
    </div>
  );
};

export default TextEditor;
