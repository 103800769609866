import React, { useEffect, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Flex,
  Table,
  Text,
  TextField,
  Tooltip,
  Badge,
  Spinner,
} from "@radix-ui/themes";
import { gray, grayA, violet } from "@radix-ui/colors";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import {
  ArrowDownWideNarrow,
  ListChecks,
  SearchX,
  Radar,
  Lock,
  Users,
  ArrowUpWideNarrow,
  CircleCheckBig,
  CircleSlash,
  Telescope,
} from "lucide-react";

import { useMonitor } from "./context/MonitorContext";
import { toShortDate, toTimeAgo } from "./utils";
import "./MonitorDetail.css";

const AccessIcon = ({ access }) => {
  const iconProps = {
    size: 16,
    strokeWidth: 2,
    color: gray.gray8,
    style: { flexShrink: 0 },
  };

  const accessTypes = {
    private: {
      icon: <Lock {...iconProps} />,
      tooltip: `Private to you:
• Only runs on your accounts
• Won't be visible to teammates`,
    },
    shared: {
      icon: <Users {...iconProps} />,
      tooltip: `Shared with team:
• Runs on all accounts
• Visible to all teammates`,
    },
  };

  const type = accessTypes[access];
  if (!type) return null;

  return (
    <Tooltip
      content={type.tooltip}
      style={{
        whiteSpace: "pre-wrap",
      }}
    >
      {type.icon}
    </Tooltip>
  );
};

const Toolbar = ({ title }) => {
  return (
    <Flex
      direction="column"
      justify="between"
      align="center"
      style={{ borderBottom: `1px solid ${gray.gray6}` }}
    >
      <Flex
        align="center"
        justify="between"
        px="5"
        style={{
          width: "100%",
          height: 60,
        }}
      >
        <Flex align="center" gap="10px">
          <Radar size={20} strokeWidth={2} color={gray.gray8} />
          <Flex align="center" gap="10px">
            <Text size="5" weight="medium">
              Monitors
            </Text>
            <Text size="5" weight="medium" style={{ color: gray.gray7 }}>
              /
            </Text>
            <Text
              size="5"
              weight="medium"
              truncate
              title={title}
              style={{ color: violet.violet9, maxWidth: 640 }}
            >
              {title}
            </Text>
            <AccessIcon access={"private"} />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const Search = ({ onSearch, isDisabled }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    onSearch(term);
  };

  return (
    <TextField.Root
      size="2"
      placeholder="Search..."
      radius="large"
      style={{
        width: "224px",
        height: "28px",
        boxShadow: "none",
        border: "1px solid var(--gray-4)",
      }}
      value={searchTerm}
      onChange={handleSearch}
      disabled={isDisabled}
    >
      <TextField.Slot>
        <MagnifyingGlassIcon height="16" width="16" />
      </TextField.Slot>
    </TextField.Root>
  );
};

const AccountRow = ({
  account: { customer_account, detected: isDetected, rationale, evidence },
}) => {
  const DetectedIcon = isDetected ? CircleCheckBig : CircleSlash;

  return (
    <Table.Row className="table-row">
      <Table.Cell>
        <Flex align="center" gap="2" ml="3">
          <Text size="2" weight="medium">
            {customer_account.name}
          </Text>
        </Flex>
      </Table.Cell>
      <Table.Cell>
        <Flex
          align="center"
          gap="6px"
          style={{ color: isDetected ? "var(--green-9)" : "var(--red-9)" }}
        >
          <DetectedIcon size={12} strokeWidth={2.5} style={{ marginTop: 1 }} />
          <Text weight="medium">{isDetected ? "Yes" : "No"}</Text>
        </Flex>
      </Table.Cell>
      <Table.Cell pr="6">
        <Text size="2" color="gray">
          {rationale}
        </Text>
      </Table.Cell>
      <Table.Cell pr="5">
        <Text size="2" color="gray" style={{ whiteSpace: "pre-wrap" }}>
          {evidence}
        </Text>
      </Table.Cell>
    </Table.Row>
  );
};

const SortableColumnHeader = ({
  label,
  sortKeys,
  sortBy,
  sortDirection,
  onSortChange,
}) => {
  const isCurrentSort = Array.isArray(sortKeys)
    ? sortKeys.includes(sortBy)
    : sortBy === sortKeys;

  const SortDirectionIcon =
    sortDirection === "asc" ? ArrowUpWideNarrow : ArrowDownWideNarrow;

  return (
    <Flex
      align="center"
      gap="2"
      style={{
        cursor: "pointer",
        userSelect: "none",
        color: isCurrentSort ? violet.violet10 : grayA.grayA10,
      }}
      onClick={() => onSortChange(sortKeys)}
    >
      {label}
      {isCurrentSort && (
        <SortDirectionIcon
          size={12}
          strokeWidth={1.5}
          color={violet.violet10}
        />
      )}
    </Flex>
  );
};

const AccountsTable = ({ accounts }) => {
  const [sortBy, setSortBy] = useState("detected");
  const [sortDirection, setSortDirection] = useState("desc"); // "asc" or "desc"

  const handleSort = (key) => {
    if (sortBy === key) {
      // If clicking the same column, toggle direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // If clicking new column, set it with default desc direction
      setSortBy(key);
      setSortDirection("desc");
    }
  };

  const sortedAccounts = useMemo(() => {
    return [...accounts].sort((a, b) => {
      let comparison = 0;

      if (sortBy === "detected") {
        // First sort by detected
        if (a.detected !== b.detected) {
          comparison = b.detected ? 1 : -1;
        } else {
          // If detected is the same, fall back to name
          comparison = a.customer_account.name.localeCompare(
            b.customer_account.name
          );
        }
      } else if (sortBy === "name") {
        comparison = a.customer_account.name.localeCompare(
          b.customer_account.name
        );
      }

      // Reverse the comparison if ascending order
      return sortDirection === "asc" ? -comparison : comparison;
    });
  }, [accounts, sortBy, sortDirection]);

  return (
    <Table.Root className="accounts-table" variant="surface">
      <Table.Header style={{ position: "sticky", top: 0 }}>
        <Table.Row>
          <Table.ColumnHeaderCell style={{ width: "20%" }}>
            <SortableColumnHeader
              label={
                <Flex align="center" ml="3">
                  Account
                </Flex>
              }
              sortKeys="name"
              sortBy={sortBy}
              sortDirection={sortDirection}
              onSortChange={handleSort}
            />
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell style={{ width: "8%" }}>
            <SortableColumnHeader
              label="Matched"
              sortKeys="detected"
              sortBy={sortBy}
              sortDirection={sortDirection}
              onSortChange={handleSort}
            />
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell style={{ width: "30%" }}>
            <Text
              style={{
                color: grayA.grayA10,
                cursor: "default",
                userSelect: "none",
              }}
            >
              Rationale
            </Text>
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell style={{ width: "30%" }}>
            <Text
              style={{
                color: grayA.grayA10,
                cursor: "default",
                userSelect: "none",
              }}
            >
              Evidence
            </Text>
          </Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {sortedAccounts.map((account, index) => (
          <AccountRow key={index} account={account} />
        ))}
      </Table.Body>
    </Table.Root>
  );
};

const EmptyState = ({ title, description, icon: Icon, maxWidth = 256 }) => (
  <Flex
    direction="column"
    align="center"
    justify="center"
    py="9"
    style={{ height: "100%" }}
  >
    <Icon height={120} width={120} strokeWidth={1.25} color={gray.gray7} />
    <Flex
      direction="column"
      align="center"
      gap="1"
      mt="2"
      style={{ maxWidth: maxWidth, width: "100%" }}
    >
      <Text size="4" weight="medium" align="center">
        {title}
      </Text>
      <Text size="2" align="center" style={{ color: gray.gray9 }}>
        {description}
      </Text>
    </Flex>
  </Flex>
);

const Results = ({ monitor }) => {
  const [searchTerm, setSearchTerm] = React.useState("");

  const results = monitor.recent_results;
  const isInitialState = results.length === 0;
  const evaluatedAt = results?.reduce(
    (earliest, result) =>
      !earliest || result.evaluated_at < earliest
        ? result.evaluated_at
        : earliest,
    null
  );

  const filteredResults = React.useMemo(() => {
    if (!results) return [];
    if (!searchTerm) return results;

    const lowerSearch = searchTerm.toLowerCase();
    return results.filter((item) => {
      return (
        item.customer_account.name?.toLowerCase().includes(lowerSearch) ||
        item.rationale?.toLowerCase().includes(lowerSearch) ||
        item.evidence?.toLowerCase().includes(lowerSearch)
      );
    });
  }, [results, searchTerm]);

  const hasNoResultsAfterFilter =
    !isInitialState && filteredResults.length === 0;

  const handleSearch = (term) => {
    setSearchTerm(term.trim());
  };

  const CenteredContainer = ({ children }) => (
    <Flex
      align="center"
      justify="center"
      style={{ height: "calc(100vh - 144px)" }}
    >
      {children}
    </Flex>
  );

  return (
    <Flex direction="column" style={{ width: "100%", height: "100%" }}>
      <Flex
        align="center"
        justify="between"
        px="5"
        style={{
          background: "var(--gray-2)",
          borderBottom: `1px solid ${grayA.grayA5}`,
          minHeight: 48,
          maxHeight: 48,
          position: "sticky",
          top: 0,
          zIndex: 100,
        }}
      >
        <Flex align="center" gap="10px">
          <ListChecks size={18} color={gray.gray8} />
          <Text size="4" weight="medium">
            Latest run
          </Text>
          {!isInitialState && (
            <Flex
              align="center"
              gap="2"
              px="1"
              style={{
                background: gray.gray3,
                borderRadius: 4,
              }}
            >
              <Text size="2" weight="medium" style={{ color: gray.gray10 }}>
                {filteredResults.length}
              </Text>
            </Flex>
          )}
          {!isInitialState && (
            <Tooltip
              content={`${toShortDate(evaluatedAt)} at ${new Date(evaluatedAt).toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })}`}
            >
              <Badge
                size="1"
                variant="outline"
                color="gray"
                ml="1"
                style={{ opacity: 0.7, cursor: "default" }}
              >
                {toTimeAgo(evaluatedAt, true)}
              </Badge>
            </Tooltip>
          )}
        </Flex>
        <Search onSearch={handleSearch} isDisabled={isInitialState} />
      </Flex>

      {isInitialState ? (
        <CenteredContainer>
          <EmptyState
            title="Results coming soon..."
            description="Your monitor is set up and scheduled to run on your accounts tonight."
            icon={Telescope}
          />
        </CenteredContainer>
      ) : hasNoResultsAfterFilter ? (
        <CenteredContainer>
          <EmptyState
            title="No matching accounts found"
            description="We didn't find any accounts that match your search term."
            icon={SearchX}
          />
        </CenteredContainer>
      ) : (
        <AccountsTable accounts={filteredResults} />
      )}
    </Flex>
  );
};

const Loading = () => (
  <Flex
    justify="center"
    align="center"
    style={{ height: "calc(100vh - 64px)" }}
  >
    <Flex direction="column" align="center" gap="4">
      <Spinner size="3" style={{ transform: "scale(1.5)" }} />
      <Text size="2" weight="medium" style={{ color: gray.gray10 }}>
        Loading...
      </Text>
    </Flex>
  </Flex>
);

const MonitorDetail = () => {
  const { id } = useParams();
  const { getMonitor } = useMonitor();
  const [monitor, setMonitor] = useState(null);

  useEffect(() => {
    if (id) {
      getMonitor(id).then(setMonitor);
    }
  }, [id, getMonitor]);

  return (
    <Flex className="monitor-detail-container" direction="column">
      <Toolbar title={monitor?.prompt || "..."} />
      <Flex
        direction="column"
        align="center"
        style={{
          minWidth: 860,
          margin: "0 auto",
          width: "100%",
          height: "100%",
        }}
      >
        {monitor ? <Results monitor={monitor} /> : <Loading />}
      </Flex>
    </Flex>
  );
};

export default MonitorDetail;
