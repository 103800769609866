import React, { createContext, useCallback, useContext } from "react";
import { useAuth } from "./AuthContext";
import { BASE_URL } from "../App";

const AccountsContext = createContext();

export const useAccounts = () => {
  return useContext(AccountsContext);
};

export const AccountsProvider = ({ children }) => {
  const { user } = useAuth();

  const getAccountsHomeData = useCallback(async () => {
    const response = await fetch(`${BASE_URL}/api/v1/accounts-home`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    const accounts = await response.json();

    return accounts;
  }, []);

  const getAccounts = useCallback(async (userId = null) => {
    const url = new URL(`${BASE_URL}/api/v1/accounts`);
    if (userId) {
      url.searchParams.append("book_of_business", userId);
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    });

    const accounts = await response.json();
    return accounts.customer_accounts;
  }, []);

  return (
    <AccountsContext.Provider value={{ getAccountsHomeData, getAccounts }}>
      {children}
    </AccountsContext.Provider>
  );
};
