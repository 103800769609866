import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Button,
  Flex,
  Popover,
  ScrollArea,
  Table,
  Text,
  TextField,
  Tooltip,
  Spinner,
  Badge,
  Skeleton,
} from "@radix-ui/themes";
import { gray, grayA, violet } from "@radix-ui/colors";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import {
  CircleHelp as HelpIcon,
  ArrowDownWideNarrow,
  Rocket,
  Play,
  Lightbulb,
  Database,
  ListChecks,
  ChevronDown,
  Filter as FilterIcon,
  Radar,
  Lock,
  ArrowUpWideNarrow,
  CircleCheckBig,
  CircleSlash,
  Telescope,
  User,
  SearchX,
} from "lucide-react";
import TurndownService from "turndown";

import { useAccounts } from "./context/AccountsContext";
import { useMonitor } from "./context/MonitorContext";
import Select from "./Select";
import TextEditor from "./TextEditor";
import BG from "./assets/bg-accent.png";
import "./MonitorEditor.css";
import { useAuth } from "./context/AuthContext";

const DeployPopover = ({ trigger, onDeploy, isDeploying }) => {
  const [title, setTitle] = useState("");
  const [frequency, setFrequency] = useState("daily");

  const frequencyOptions = [
    { value: "daily", label: "Daily" },
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
  ];

  return (
    <Popover.Root>
      <Popover.Trigger asChild>{trigger}</Popover.Trigger>
      <Popover.Content
        width="336px"
        size="2"
        align="end"
        sideOffset={10}
        style={{ overflow: "visible" }}
      >
        <Flex direction="column" gap="5" p="2">
          <Flex direction="column" gap="3">
            <Text size="5" weight="medium">
              Deploy monitor
            </Text>

            <Text size="2" style={{ color: gray.gray10 }}>
              Once deployed, we'll continuously run your monitor and check for
              matching accounts.
            </Text>

            <Flex direction="column" gap="1" style={{ color: gray.gray10 }}>
              <Text size="2">Your new monitor:</Text>
              <Text as="ul" size="2" style={{ paddingLeft: "8px" }}>
                <li>Will not affect any underlying data</li>
                <li>Only runs on your accounts</li>
                <li>Won't be visible to teammates</li>
                <li>Can be turned off at any time</li>
              </Text>
            </Flex>
          </Flex>

          {/* <Flex direction="column" gap="4">
            <Flex direction="column" gap="1">
              <Text size="2" weight="medium">
                Title
              </Text>
              <TextField.Root
                size="2"
                placeholder="Enter a monitor title..."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                radius="large"
              ></TextField.Root>
            </Flex>

            <Flex direction="column" gap="1">
              <Text size="2" weight="medium">
                Run frequency
              </Text>
              <Select.Root value={frequency} onValueChange={setFrequency}>
                <Select.Trigger>
                  <Flex
                    align="center"
                    justify="between"
                    px="3"
                    style={{
                      cursor: "pointer",
                      userSelect: "none",
                      border: `1px solid ${gray.gray6}`,
                      borderRadius: 6,
                      height: 32,
                    }}
                  >
                    <Text size="2">
                      {
                        frequencyOptions.find((opt) => opt.value === frequency)
                          ?.label
                      }
                    </Text>
                    <ChevronDown size={14} color={gray.gray8} />
                  </Flex>
                </Select.Trigger>
                <Select.Content>
                  {frequencyOptions.map((option) => (
                    <Select.Item key={option.value} value={option.value}>
                      {option.label}
                    </Select.Item>
                  ))}
                </Select.Content>
              </Select.Root>
            </Flex>
          </Flex> */}

          <Flex justify="end" gap="3" mt="2">
            <Popover.Close>
              <Button variant="soft" size="2" radius="large" color="gray">
                Cancel
              </Button>
            </Popover.Close>
            <Button
              size="2"
              radius="large"
              onClick={onDeploy}
              disabled={isDeploying}
            >
              {isDeploying ? (
                <>
                  <Spinner />
                  Deploying...
                </>
              ) : (
                "Save & deploy"
              )}
            </Button>
          </Flex>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
};

const Toolbar = ({ results, onDeploy, isDeploying }) => {
  return (
    <Flex
      direction="column"
      justify="between"
      align="center"
      style={{ borderBottom: `1px solid ${gray.gray6}` }}
    >
      <Flex
        align="center"
        justify="between"
        px="5"
        style={{
          width: "100%",
          height: 60,
        }}
      >
        <Flex align="center" gap="10px">
          <Radar size={20} strokeWidth={2} color={gray.gray8} />
          <Flex align="center" gap="10px">
            <Text size="5" weight="medium">
              Monitors
            </Text>
            <Text size="5" weight="medium" style={{ color: gray.gray7 }}>
              /
            </Text>
            <Text size="5" weight="medium" style={{ color: violet.violet9 }}>
              Untitled
            </Text>
            <Tooltip
              content={`Private to you:
• Only runs on your accounts
• Won't be visible to teammates`}
              style={{
                whiteSpace: "pre-wrap",
              }}
            >
              <Lock size={16} strokeWidth={2} color={gray.gray8} />
            </Tooltip>
          </Flex>
        </Flex>
        <DeployPopover
          onDeploy={onDeploy}
          isDeploying={isDeploying}
          trigger={
            <Button size="2" radius="large" variant="soft" disabled={!results}>
              <Rocket size={16} />
              Deploy
              <ChevronDown size={14} />
            </Button>
          }
        />
      </Flex>
    </Flex>
  );
};

const Search = ({ value, onSearch, isDisabled }) => {
  return (
    <TextField.Root
      size="2"
      placeholder="Search..."
      radius="large"
      style={{
        width: "224px",
        height: "28px",
        boxShadow: "none",
        border: "1px solid var(--gray-4)",
      }}
      value={value}
      onChange={(e) => onSearch(e.target.value)}
      disabled={isDisabled}
    >
      <TextField.Slot>
        <MagnifyingGlassIcon height="16" width="16" />
      </TextField.Slot>
    </TextField.Root>
  );
};

const IntroHeader = ({ ...props }) => {
  return (
    <Flex
      direction="column"
      align="center"
      style={{ maxWidth: 680 }}
      {...props}
    >
      <Text
        as="h2"
        size="8"
        weight="medium"
        align="center"
        mt="5"
        mb="2"
        style={{ letterSpacing: "-1.5px" }}
      >
        Experiment & develop your monitor
      </Text>
      <Text size="4" align="center" style={{ color: gray.gray10 }}>
        Describe the account behavior to detect. Once saved, we'll continuously
        analyze your selected data source and identify accounts that match.
      </Text>
    </Flex>
  );
};

const DataSourceSelect = ({
  selectedRecordTypes,
  setSelectedRecordTypes,
  recordTypes,
  disabled,
}) => {
  // Get the single selected value (take the first one if any exists)
  const selectedValue =
    [...selectedRecordTypes.canonical, ...selectedRecordTypes.custom][0] || "";

  return (
    <Select.Root
      value={selectedValue}
      onValueChange={(value) => {
        // We keep the logic and comments intact
        const canonical = [];
        const custom = [];

        // Sort the selected value into the appropriate array
        if (recordTypes.canonical.find((source) => source.value === value)) {
          canonical.push(value);
        }
        if (recordTypes.custom.find((source) => source.value === value)) {
          custom.push(value);
        }

        setSelectedRecordTypes({ custom, canonical });
      }}
      disabled={disabled}
    >
      <Select.Trigger>
        <Flex
          className="data-source-select-trigger"
          align="center"
          gap="2"
          pl="3"
          pr="2"
          style={{
            color: selectedValue ? gray.gray12 : gray.gray11,
          }}
        >
          <Database size={14} color={gray.gray8} style={{ marginRight: 2 }} />
          <Text size="2">
            {selectedValue
              ? [...recordTypes.canonical, ...recordTypes.custom].find(
                  (s) => s.value === selectedValue
                )?.name || "Choose data source"
              : "Choose data source"}
          </Text>
          <ChevronDown size={14} color={gray.gray8} />
        </Flex>
      </Select.Trigger>
      <Select.Content>
        {[...recordTypes.canonical, ...recordTypes.custom].map((source) => (
          <Select.Item key={source.value} value={source.value}>
            <Flex
              direction="column"
              gap="2px"
              style={{
                width: 232,
              }}
            >
              <Text>{source.name}</Text>
              <Text size="1" style={{ opacity: 0.5 }}>
                {source.description}
              </Text>
            </Flex>
          </Select.Item>
        ))}
      </Select.Content>
    </Select.Root>
  );
};

const Filter = ({ icon: Icon, title, value, options, onChange }) => {
  const selectedOption =
    options.find((option) => option.value === value) || options[0];

  return (
    <Flex
      display={{ initial: "none", sm: "flex" }}
      style={{ userSelect: "none" }}
    >
      <Select.Root value={value} onValueChange={onChange}>
        <Select.Trigger>
          <Flex
            align="center"
            gap="6px"
            style={{
              border: `1px solid ${gray.gray4}`,
              borderRadius: 6,
              padding: "8px 12px",
              width: "100%",
            }}
          >
            <Icon size={12} color={grayA.grayA9} />
            {title && (
              <Text size="1" weight="medium" style={{ color: grayA.grayA9 }}>
                {title}
              </Text>
            )}
            <Text size="1" weight="medium" style={{ color: grayA.grayA11 }}>
              {selectedOption.label}
            </Text>
            <ChevronDown size={14} color={gray.gray9} />
          </Flex>
        </Select.Trigger>
        <Select.Content position="popper">
          {options.map((option) => (
            <Select.Item key={option.value} value={option.value}>
              {option.label}
            </Select.Item>
          ))}
        </Select.Content>
      </Select.Root>
    </Flex>
  );
};

const Filters = ({ disabled, filters, onFilterChange }) => {
  const { user } = useAuth();

  const numFiltersSet = Object.values(filters).filter(
    (value) => value !== "all" && value !== null
  ).length;

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Flex
          className={`filters-trigger ${disabled ? "disabled" : ""}`}
          align="center"
          gap="2"
          pl="3"
          pr="2"
          style={{
            color: numFiltersSet > 0 ? gray.gray12 : gray.gray11,
          }}
        >
          <FilterIcon size={14} color={gray.gray8} style={{ marginRight: 4 }} />
          <Text size="2">Filters</Text>
          {numFiltersSet > 0 && (
            <Flex
              align="center"
              px="1"
              style={{
                background: grayA.grayA3,
                borderRadius: 4,
              }}
            >
              <Text size="1" weight="medium" style={{ color: gray.gray10 }}>
                {numFiltersSet}
              </Text>
            </Flex>
          )}
          <ChevronDown size={14} color={gray.gray8} />
        </Flex>
      </Popover.Trigger>
      <Popover.Content
        side="bottom"
        align="start"
        style={{
          backgroundColor: "white",
          borderRadius: 8,
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          border: `1px solid ${gray.gray4}`,
          overflow: "visible",
        }}
      >
        <Flex direction="column" gap="3">
          <Text size="2" weight="medium">
            Account
          </Text>

          <Flex direction="column" gap="3">
            <Filter
              icon={User}
              title="Assigned to:"
              value={filters.assignedTo}
              options={[
                { value: null, label: "Anyone" },
                { value: user.id, label: "Myself" },
                { value: "user_d11af89a60c1410f", label: "Mel Martell" },
              ]}
              onChange={(value) =>
                onFilterChange({ ...filters, assignedTo: value })
              }
            />
          </Flex>
        </Flex>
      </Popover.Content>
    </Popover.Root>
  );
};

const Editor = ({
  prompt,
  setPrompt,
  selectedRecordTypes,
  setSelectedRecordTypes,
  recordTypes,
  onTestMonitor,
  onSaveMonitor,
  results,
  isLoading,
  lastTestedState,
  filters,
  onFilterChange,
}) => {
  // Check if form is valid (has prompt and at least one data source)
  const isValid = useMemo(() => {
    const hasPrompt = prompt?.replace(/<[^>]*>/g, "").trim().length > 0;
    const hasDataSources =
      selectedRecordTypes.custom.length > 0 ||
      selectedRecordTypes.canonical.length > 0;
    return hasPrompt && hasDataSources;
  }, [prompt, selectedRecordTypes]);

  // Helper to check if the current editor state matches the last tested state
  const isSameAsLastTest = useMemo(() => {
    if (!lastTestedState) return false;
    // Check prompt
    if (prompt !== lastTestedState.prompt) return false;

    // Check custom record array
    if (
      selectedRecordTypes.custom.length !== lastTestedState.custom.length ||
      selectedRecordTypes.custom
        .slice()
        .sort()
        .some((val, idx) => val !== lastTestedState.custom.slice().sort()[idx])
    ) {
      return false;
    }

    // Check canonical record array
    if (
      selectedRecordTypes.canonical.length !==
        lastTestedState.canonical.length ||
      selectedRecordTypes.canonical
        .slice()
        .sort()
        .some(
          (val, idx) => val !== lastTestedState.canonical.slice().sort()[idx]
        )
    ) {
      return false;
    }
    return true;
  }, [prompt, selectedRecordTypes, lastTestedState]);

  // Decide if the test button should be disabled
  // const isTestDisabled = !isValid || isLoading || (results && isSameAsLastTest);
  const isTestDisabled = !isValid || isLoading;

  // Keyboard shortcut handler
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Check for Cmd+Enter (Mac) or Ctrl+Enter (Windows)
      if ((e.metaKey || e.ctrlKey) && e.key === "Enter") {
        if (!isTestDisabled) {
          onTestMonitor();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [onTestMonitor, isTestDisabled]);

  return (
    <Flex direction="column" gap="3" style={{ width: "100%", maxWidth: 820 }}>
      <Flex
        className="monitor-prompt-editor"
        direction="column"
        gap="4"
        px="5"
        pb="20px"
      >
        <Flex
          align="center"
          justify="between"
          px="5"
          py="3"
          style={{
            borderBottom: `1px solid ${grayA.grayA4}`,
            margin: "0 -24px",
          }}
        >
          <Text size="3" weight="medium">
            What should Lateral check your accounts for?
          </Text>
          <Tooltip
            style={{ whiteSpace: "pre-wrap" }}
            content={`Be specific about:
• What behavior or change you're looking for
• Any relevant timeframes
• What indicates a meaningful pattern`}
          >
            <HelpIcon size={20} color={gray.gray8} />
          </Tooltip>
        </Flex>
        <TextEditor
          content={prompt}
          onChange={setPrompt}
          disabled={isLoading}
          placeholder="Detect accounts that have..."
        />

        <Flex justify="between" align="center" mt="2">
          <Flex align="center" gap="3">
            <DataSourceSelect
              selectedRecordTypes={selectedRecordTypes}
              setSelectedRecordTypes={setSelectedRecordTypes}
              recordTypes={recordTypes}
              disabled={isLoading}
            />
            <Filters
              disabled={isLoading}
              filters={filters}
              onFilterChange={onFilterChange}
            />
          </Flex>

          <Flex gap="3">
            {isLoading ? (
              <Tooltip
                content="Analyzing your selected data source to detect matching accounts..."
                style={{ maxWidth: 240 }}
              >
                <Button size="2" radius="large" disabled>
                  <Spinner />
                  Testing...
                </Button>
              </Tooltip>
            ) : (
              <Button
                size="2"
                radius="large"
                disabled={isTestDisabled}
                onClick={onTestMonitor}
              >
                <Play size={14} />
                Test
                <Flex
                  align="center"
                  ml="1"
                  px="6px"
                  py="2px"
                  style={{
                    background: "rgba(256, 256, 256, 0.1)",
                    borderRadius: 4,
                  }}
                >
                  <Text size="1" weight="medium">
                    ⌘{" "}
                    <span
                      style={{
                        fontWeight: 400,
                        opacity: 0.6,
                      }}
                    >
                      +
                    </span>{" "}
                    ↵
                  </Text>
                </Flex>
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

const ExampleCard = ({ example, onSelect }) => {
  return (
    <Flex
      className="example-card"
      gap="3"
      px="4"
      py="3"
      onClick={() => onSelect(example)}
    >
      <Flex
        align="center"
        justify="center"
        style={{
          background: "rgba(255, 191, 0, 0.1)",
          borderRadius: "8px",
          width: "28px",
          height: "28px",
          flexShrink: 0,
        }}
      >
        <Lightbulb size={16} color="#FFB300" />
      </Flex>

      <Flex direction="column" gap="1">
        {/* Monitor name */}
        <Text size="1" weight="medium">
          {example.example_title}
        </Text>

        {/* Data sources */}
        <Flex gap="6px" align="center">
          <Database size={10} color={gray.gray8} />
          {example.example_data_sources?.map((source, index) => (
            <Flex key={index} align="center" gap="1">
              <Text size="1" color="gray">
                {source}
              </Text>
            </Flex>
          ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

const LoadingRow = ({ accountName }) => {
  const placeholderText = {
    rationale:
      "No bill records were provided in the data sample, making it impossible to determine if any bills were uploaded today.",
    evidence:
      "The schema for Quickbooks Bills table was provided, but no actual bill records were included in the data to examine. This is likely indicative of an issue.",
  };

  return (
    <Table.Row className="table-row">
      <Table.Cell>
        <Flex align="center" gap="2" ml="3">
          <Text size="2" weight="medium">
            {accountName}
          </Text>
        </Flex>
      </Table.Cell>
      <Table.Cell>
        <Flex align="center" gap="6px">
          <Spinner />
          {/* <Text weight="medium">Loading...</Text> */}
        </Flex>
      </Table.Cell>
      <Table.Cell pr="6">
        <Skeleton>{placeholderText.rationale}</Skeleton>
      </Table.Cell>
      <Table.Cell pr="5">
        <Skeleton>{placeholderText.evidence}</Skeleton>
      </Table.Cell>
    </Table.Row>
  );
};

const AccountRow = ({ account: { monitor_answer, account } }) => {
  if (monitor_answer.loading) {
    return <LoadingRow accountName={account.name} />;
  }

  // If it's not loading, render the final data
  const isDetected = monitor_answer.detected;
  const DetectedIcon = isDetected ? CircleCheckBig : CircleSlash;
  return (
    <Table.Row className="table-row">
      <Table.Cell>
        <Flex align="center" gap="2" ml="3">
          <Text size="2" weight="medium">
            {account.name}
          </Text>
        </Flex>
      </Table.Cell>
      <Table.Cell>
        <Flex
          align="center"
          gap="6px"
          style={{ color: isDetected ? "var(--green-9)" : "var(--red-9)" }}
        >
          <DetectedIcon size={12} strokeWidth={2.5} style={{ marginTop: 1 }} />
          <Text weight="medium">{isDetected ? "Yes" : "No"}</Text>
        </Flex>
      </Table.Cell>
      <Table.Cell pr="6">
        <Text size="2" color="gray">
          {monitor_answer.rationale}
        </Text>
      </Table.Cell>
      <Table.Cell pr="5">
        <Text size="2" color="gray" style={{ whiteSpace: "pre-wrap" }}>
          {monitor_answer.evidence}
        </Text>
      </Table.Cell>
    </Table.Row>
  );
};

const SortableColumnHeader = ({
  label,
  sortKeys,
  sortBy,
  sortDirection,
  onSortChange,
}) => {
  const isCurrentSort = Array.isArray(sortKeys)
    ? sortKeys.includes(sortBy)
    : sortBy === sortKeys;

  const SortDirectionIcon =
    sortDirection === "asc" ? ArrowUpWideNarrow : ArrowDownWideNarrow;

  return (
    <Flex
      align="center"
      gap="2"
      style={{
        cursor: "pointer",
        userSelect: "none",
        color: isCurrentSort ? violet.violet10 : grayA.grayA10,
      }}
      onClick={() => onSortChange(sortKeys)}
    >
      {label}
      {isCurrentSort && (
        <SortDirectionIcon
          size={12}
          strokeWidth={1.5}
          color={violet.violet10}
        />
      )}
    </Flex>
  );
};

const AccountsTable = ({ accounts }) => {
  const [sortBy, setSortBy] = useState("name");
  const [sortDirection, setSortDirection] = useState("desc"); // "asc" or "desc"

  const handleSort = (key) => {
    if (sortBy === key) {
      // If clicking the same column, toggle direction
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      // If clicking new column, set it with default desc direction
      setSortBy(key);
      setSortDirection("desc");
    }
  };

  const sortedAccounts = useMemo(() => {
    return [...accounts].sort((a, b) => {
      let comparison = 0;

      if (sortBy === "detected") {
        // First sort by detected
        if (a.monitor_answer.detected !== b.monitor_answer.detected) {
          comparison = b.monitor_answer.detected ? 1 : -1;
        } else {
          // If detected is the same, fall back to name
          comparison = a.account.name.localeCompare(b.account.name);
        }
      } else if (sortBy === "name") {
        comparison = a.account.name.localeCompare(b.account.name);
      }

      // Reverse the comparison if ascending order
      return sortDirection === "asc" ? -comparison : comparison;
    });
  }, [accounts, sortBy, sortDirection]);

  return (
    <Table.Root className="accounts-table" variant="surface">
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell style={{ width: "20%" }}>
            <SortableColumnHeader
              label={
                <Flex align="center" ml="3">
                  Account
                </Flex>
              }
              sortKeys="name"
              sortBy={sortBy}
              sortDirection={sortDirection}
              onSortChange={handleSort}
            />
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell style={{ width: "8%" }}>
            <SortableColumnHeader
              label="Matched"
              sortKeys="detected"
              sortBy={sortBy}
              sortDirection={sortDirection}
              onSortChange={handleSort}
            />
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell style={{ width: "30%" }}>
            <Text
              style={{
                color: grayA.grayA10,
                cursor: "default",
                userSelect: "none",
              }}
            >
              Rationale
            </Text>
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell style={{ width: "30%" }}>
            <Text
              style={{
                color: grayA.grayA10,
                cursor: "default",
                userSelect: "none",
              }}
            >
              Evidence
            </Text>
          </Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {sortedAccounts.map((account, index) => (
          <AccountRow key={index} account={account} />
        ))}
      </Table.Body>
    </Table.Root>
  );
};

const EmptyState = ({ title, description, icon: Icon, maxWidth = 256 }) => (
  <Flex
    direction="column"
    align="center"
    justify="center"
    py="9"
    style={{ height: "100%" }}
  >
    <Icon height={120} width={120} strokeWidth={1.25} color={gray.gray7} />
    <Flex
      direction="column"
      align="center"
      gap="1"
      mt="2"
      style={{ maxWidth: maxWidth, width: "100%" }}
    >
      <Text size="4" weight="medium" align="center">
        {title}
      </Text>
      <Text size="2" align="center" style={{ color: gray.gray9 }}>
        {description}
      </Text>
    </Flex>
  </Flex>
);

const Results = ({ results, searchTerm, onSearch }) => {
  const isInitialState = results == null;
  const anyLoading =
    Array.isArray(results) && results.some((r) => r.monitor_answer.loading);

  const filteredResults = useMemo(() => {
    if (!results) return [];
    if (!searchTerm) return results;

    const lowerSearch = searchTerm.trim().toLowerCase();
    return results.filter(({ account, monitor_answer }) => {
      return (
        account.name?.toLowerCase().includes(lowerSearch) ||
        monitor_answer.rationale?.toLowerCase().includes(lowerSearch) ||
        monitor_answer.evidence?.toLowerCase().includes(lowerSearch)
      );
    });
  }, [results, searchTerm]);

  const hasNoResultsAfterFilter =
    !isInitialState && filteredResults.length === 0;

  return (
    <Flex direction="column" style={{ width: "100%", height: "100%" }}>
      {/* Results header */}
      <Flex
        align="center"
        justify="between"
        px="5"
        style={{
          background: "var(--gray-a2)",
          borderTop: `1px solid ${grayA.grayA5}`,
          borderBottom:
            (isInitialState || hasNoResultsAfterFilter) &&
            `1px solid ${grayA.grayA5}`,
          minHeight: 48,
          maxHeight: 48,
        }}
      >
        <Flex align="center" gap="10px">
          <ListChecks size={18} color={gray.gray8} />
          <Text size="4" weight="medium">
            Results
          </Text>
          {Array.isArray(results) && (
            <Tooltip
              content="Testing runs on a small, random sample of your accounts. Once your monitor is deployed, it'll run on all your accounts."
              style={{ maxWidth: 248 }}
            >
              <Badge
                size="1"
                variant="outline"
                color="gray"
                ml="1"
                style={{ opacity: 0.7, cursor: "default" }}
              >
                Sample
              </Badge>
            </Tooltip>
          )}
        </Flex>
        <Search
          value={searchTerm}
          onSearch={onSearch}
          isDisabled={isInitialState || anyLoading}
        />
      </Flex>

      {isInitialState ? (
        // We haven't tested anything
        <EmptyState
          title="No results yet"
          description="Test your monitor to get started"
          icon={Telescope}
        />
      ) : hasNoResultsAfterFilter ? (
        <EmptyState
          title="No matching accounts found"
          description="We didn't find any accounts that match your search term."
          icon={SearchX}
        />
      ) : (
        <AccountsTable accounts={filteredResults} />
      )}
    </Flex>
  );
};

const turndownService = new TurndownService({
  bulletListMarker: "-",
  codeBlockStyle: "fenced",
});

const convertHtmlToMarkdown = (html) => {
  if (!html) return "";
  return turndownService.turndown(html);
};

const NUM_ACCOUNTS_TO_TEST = 5;

// Helper function to randomly select accounts
const getRandomAccounts = (accounts, count = NUM_ACCOUNTS_TO_TEST) => {
  return accounts.sort(() => Math.random() - 0.5).slice(0, count);
};

const MonitorEditor = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getAccounts } = useAccounts();
  const { getRecordTypes, createMonitor, testDraftMonitor } = useMonitor();
  const [prompt, setPrompt] = useState("");
  const [recordTypes, setRecordTypes] = useState({
    custom: [],
    canonical: [],
  });
  const [selectedRecordTypes, setSelectedRecordTypes] = useState({
    custom: [],
    canonical: [],
  });
  const [lookbackDays, setLookbackDays] = useState(7);
  const [selectedAccounts, setSelectedAccounts] = useState([]);

  const [results, setResults] = useState(null);
  const [lastTestedState, setLastTestedState] = useState(null);
  const [isDeploying, setIsDeploying] = useState(false);
  const [filters, setFilters] = useState({ assignedTo: null });
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getRecordTypes().then(setRecordTypes);
    getAccounts(filters.assignedTo).then((accounts) => {
      if (accounts.length > 0) {
        setSelectedAccounts(getRandomAccounts(accounts));
      }
    });
  }, []);

  useEffect(() => {
    if (location.pathname === "/monitors/create") {
      resetEditor();
    }
  }, [location]);

  useEffect(() => {
    getAccounts(filters.assignedTo).then((accounts) => {
      if (accounts.length > 0) {
        setSelectedAccounts(getRandomAccounts(accounts));
      }
    });
  }, [filters.assignedTo]);

  const handleDeployMonitor = async () => {
    setIsDeploying(true);
    const data = await createMonitor(
      convertHtmlToMarkdown(prompt),
      selectedRecordTypes.custom[0],
      lookbackDays
    );
    setIsDeploying(false);

    navigate(`/monitors`);
  };

  const handleTestMonitor = async () => {
    setSearchTerm("");

    // If the user hasn't selected accounts, we fetch a small random sample
    const accountsToTest = selectedAccounts.length
      ? selectedAccounts
      : await getAccounts(filters.assignedTo).then((accounts) =>
          getRandomAccounts(accounts)
        );

    // If no accounts or no data sources, do nothing
    if (!accountsToTest.length || selectedRecordTypes.custom.length === 0) {
      return;
    }

    // Initialize "results" with placeholders for each account. Each row has "loading: true."
    setResults(
      accountsToTest.map((account) => ({
        account,
        monitor_answer: {
          detected: false,
          rationale: "",
          evidence: "",
          loading: true,
        },
      }))
    );

    // Start the test for each account. As soon as it's finished, replace the placeholder.
    accountsToTest.forEach(async (account, index) => {
      const data = await testDraftMonitor(
        convertHtmlToMarkdown(prompt),
        selectedRecordTypes.custom[0],
        account.id,
        lookbackDays
      );
      setResults((prevResults) => {
        if (!prevResults) return prevResults;
        const newResults = [...prevResults];
        newResults[index] = {
          account: data.account,
          monitor_answer: {
            ...data.monitor_answer,
            loading: false,
          },
        };
        return newResults;
      });
    });

    // Save what we tested as "lastTestedState."
    setLastTestedState({
      prompt,
      custom: [...selectedRecordTypes.custom],
      canonical: [...selectedRecordTypes.canonical],
    });

    // Reset selectedAccounts after running
    setSelectedAccounts([]);
  };

  const handleExampleSelect = (example) => {
    setPrompt(example.example_prompt);

    // Convert example data sources to selected record types
    const newSelectedTypes = {
      custom: [],
      canonical: [],
    };

    example.example_data_sources.forEach((sourceName) => {
      // Check custom record types
      const customMatch = recordTypes.custom.find(
        (rt) => rt.name === sourceName
      );
      if (customMatch) {
        newSelectedTypes.custom.push(customMatch.value);
      }

      // Check canonical record types
      const canonicalMatch = recordTypes.canonical.find(
        (rt) => rt.name === sourceName
      );
      if (canonicalMatch) {
        newSelectedTypes.canonical.push(canonicalMatch.value);
      }
    });

    setSelectedRecordTypes(newSelectedTypes);
  };

  const resetEditor = () => {
    setPrompt("");
    setSelectedRecordTypes({ custom: [], canonical: [] });
    setResults(null);
    setLastTestedState(null);
    setFilters({ assignedTo: null });
    setSearchTerm("");
  };

  const isInitialView = !results;

  return (
    <Flex className="monitor-editor-container" direction="column">
      <Toolbar
        results={results}
        onDeploy={handleDeployMonitor}
        isDeploying={isDeploying}
      />
      <ScrollArea
        scrollbars="vertical"
        type="scroll"
        style={{ height: "calc(100vh - 79px)" }}
      >
        <Flex
          direction="column"
          align="center"
          style={{
            minWidth: 860,
            margin: "0 auto",
            width: "100%",
            height: "100%",
          }}
        >
          <Flex
            direction="column"
            align="center"
            gap="4"
            pt="8"
            pb="8"
            style={{
              width: "100%",
              background: `url(${BG}), linear-gradient(302deg, #F5F2FF 4.01%, #FDFCFE 90.89%)`,
              backgroundPosition: "right calc(100% + 396px), center",
              backgroundSize:
                "calc(535px * 1.125) calc(984px * 1.125), 100% 100%",
              backgroundBlendMode: "darken, normal",
              backgroundRepeat: "no-repeat, no-repeat",
            }}
          >
            {isInitialView && <IntroHeader mb="6" />}
            <Editor
              prompt={prompt}
              setPrompt={setPrompt}
              recordTypes={recordTypes}
              selectedRecordTypes={selectedRecordTypes}
              setSelectedRecordTypes={setSelectedRecordTypes}
              onTestMonitor={handleTestMonitor}
              onDeployMonitor={handleDeployMonitor}
              results={results}
              isLoading={results?.some((r) => r.monitor_answer.loading)}
              lastTestedState={lastTestedState}
              filters={filters}
              onFilterChange={setFilters}
            />
            {/* {isInitialView && (
              <Flex gap="4">
                {mockData.examples.map((example) => (
                  <ExampleCard
                    key={example.example_id}
                    example={example}
                    onSelect={handleExampleSelect}
                  />
                ))}
              </Flex>
            )} */}
          </Flex>

          <Results
            results={results}
            searchTerm={searchTerm}
            onSearch={setSearchTerm}
          />
        </Flex>
      </ScrollArea>
    </Flex>
  );
};

export default MonitorEditor;

const mockData = {
  examples: [
    {
      example_id: 1,
      example_title: `Detect accounts "regressing" to using Quickbooks`,
      example_prompt: `<p>I want to find accounts that meet the following criteria:</p><ul><li><p>Historically, they made bills in our system for particular vendors</p></li><li><p>Recently, those accounts "stopped" making bills in our system first, and instead started making bills in Quickbooks first. By recent I mean within the last month.</p></li></ul><p></p><p>This indicates that they're "regressing" to using Quickbooks</p><p></p><p>Notes:</p><ul><li><p>We are not worried about accounts that consistently make bills in Quickbooks first. Only those that changed behavior "away" from our system.</p></li></ul>`,
      example_data_sources: ["Quickbooks Bills"],
    },
    {
      example_id: 2,
      example_title: "Find accounts with expected cost code cleanup",
      example_prompt:
        "Detect accounts that have stopped using Quickbooks in the last 3 months.",
      example_data_sources: ["Quickbooks Bills"],
    },
    {
      example_id: 3,
      example_title: "Identify accounts that aren't using cost codes",
      example_prompt:
        "Detect accounts that have stopped using Quickbooks in the last 3 months.",
      example_data_sources: ["Quickbooks Bills"],
    },
  ],
};
