import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Text,
  Flex,
  Spinner,
  Button,
  ScrollArea,
  Table,
} from "@radix-ui/themes";
import { gray, grayA, violet } from "@radix-ui/colors";
import {
  ArrowDownWideNarrow,
  SearchX,
  Radar,
  Plus,
  Database,
  Telescope,
  ArrowUpWideNarrow,
} from "lucide-react";

import { useMonitor } from "./context/MonitorContext";
import { capitalize, toTimeAgo } from "./utils";
import MonitorsIcon from "./assets/monitors.svg";
import BG from "./assets/bg-accent.png";
import "./MonitorList.css";

const Toolbar = ({ title }) => {
  const navigate = useNavigate();
  return (
    <Flex
      direction="column"
      justify="between"
      align="center"
      style={{ borderBottom: `1px solid ${gray.gray6}` }}
    >
      <Flex
        align="center"
        justify="between"
        px="5"
        style={{ width: "100%", height: 60 }}
      >
        <Flex align="center" gap="10px">
          <Radar size={20} strokeWidth={2} color={gray.gray8} />
          <Flex align="center" gap="10px">
            <Text size="5" weight="medium">
              Monitors
            </Text>
            <Text size="5" weight="medium" style={{ color: gray.gray7 }}>
              /
            </Text>
            <Text size="5" weight="medium" style={{ color: violet.violet9 }}>
              {title}
            </Text>
          </Flex>
        </Flex>
        <Button
          size="2"
          radius="large"
          onClick={() => navigate("/monitors/create")}
        >
          <Plus size={16} />
          Create
        </Button>
      </Flex>
    </Flex>
  );
};

const IntroHeader = () => (
  <Flex
    direction="column"
    align="center"
    gap="4"
    py="56px"
    style={{
      width: "100%",
      background: `url(${BG}), linear-gradient(302deg, #F5F2FF 4.01%, #FDFCFE 90.89%)`,
      backgroundPosition: "right calc(100% + 536px), center",
      backgroundSize: "535px 984px, 100% 100%",
      backgroundBlendMode: "darken, normal",
      backgroundRepeat: "no-repeat, no-repeat",
    }}
  >
    <Flex direction="column" align="center" style={{ maxWidth: 648 }}>
      <img src={MonitorsIcon} alt="Signal" style={{ width: 80, height: 80 }} />
      <Text
        as="h2"
        size="8"
        weight="medium"
        align="center"
        mt="5"
        mb="2"
        style={{ letterSpacing: "-1.5px" }}
      >
        Continuously monitor your accounts
      </Text>
      <Text size="4" align="center" style={{ color: gray.gray10 }}>
        Monitors run on your accounts to help you detect important patterns and
        changes in your customer's behavior that might otherwise go unnoticed
      </Text>
    </Flex>
  </Flex>
);

const MonitorRow = ({ monitor, onRowClick }) => {
  return (
    <Table.Row
      className="table-row"
      onClick={() => onRowClick(monitor.id)}
      style={{ cursor: "pointer" }}
    >
      <Table.Cell>
        <Flex direction="column" gap="1" mx="3">
          <Text
            size="2"
            weight="medium"
            // truncate
            // style={{ maxWidth: 860 }}
            title={monitor.prompt}
          >
            {monitor.prompt}
          </Text>
          <Flex align="center" gap="2">
            <Flex align="center" gap="1">
              <Database size={10} color={gray.gray9} />
              <Text size="1" style={{ color: gray.gray9 }}>
                {monitor.custom_record_definition.name}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Table.Cell>
      <Table.Cell>
        <Flex direction="column" gap="1">
          {monitor.recent_results_summary.evaluated_at ? (
            <>
              <Text size="2" wrap="nowrap">
                {monitor.recent_results_summary.detected_count} accounts matched
              </Text>
              <Text size="1" style={{ color: gray.gray9 }}>
                {toTimeAgo(monitor.recent_results_summary.evaluated_at)}
              </Text>
            </>
          ) : (
            <>
              <Text size="2" style={{ color: gray.gray9 }}>
                Awaiting initial run...
              </Text>
              <Text size="1" style={{ color: gray.gray9 }}>
                Scheduled for tonight
              </Text>
            </>
          )}
        </Flex>
      </Table.Cell>
      <Table.Cell>
        <Text size="2">Daily</Text>
      </Table.Cell>
      <Table.Cell>
        <Flex direction="column" gap="1">
          <Text size="2">{toTimeAgo(monitor.created_at)}</Text>
          {/* <Text size="1" style={{ color: gray.gray9 }}>
            {monitor.created_by}
          </Text> */}
        </Flex>
      </Table.Cell>
    </Table.Row>
  );
};

const SortableColumnHeader = ({
  label,
  sortKeys,
  sortBy,
  sortDirection,
  onSortChange,
}) => {
  const isCurrentSort = Array.isArray(sortKeys)
    ? sortKeys.includes(sortBy)
    : sortBy === sortKeys;

  const SortDirectionIcon =
    sortDirection === "asc" ? ArrowUpWideNarrow : ArrowDownWideNarrow;

  return (
    <Flex
      align="center"
      gap="2"
      style={{
        cursor: "pointer",
        userSelect: "none",
        color: isCurrentSort ? violet.violet10 : grayA.grayA10,
      }}
      onClick={() => onSortChange(sortKeys)}
    >
      {label}
      {isCurrentSort && (
        <SortDirectionIcon
          size={12}
          strokeWidth={1.5}
          color={violet.violet10}
        />
      )}
    </Flex>
  );
};

const MonitorsTable = ({ monitors, onRowClick }) => {
  const [sortBy, setSortBy] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");

  const handleSort = (key) => {
    if (sortBy === key) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortBy(key);
      setSortDirection("desc");
    }
  };

  const sortedMonitors = useMemo(() => {
    return [...monitors].sort((a, b) => {
      let comparison = 0;

      switch (sortBy) {
        case "prompt":
          comparison = a.prompt.localeCompare(b.prompt);
          break;
        case "recent_results_summary.detected_count":
          comparison =
            (a.recent_results_summary?.detected_count || 0) -
            (b.recent_results_summary?.detected_count || 0);
          break;
        case "details.run_frequency":
          comparison = (a.details?.run_frequency || "").localeCompare(
            b.details?.run_frequency || ""
          );
          break;
        case "created_at":
          comparison = new Date(a.created_at) - new Date(b.created_at);
          break;
        default:
          comparison = 0;
      }

      return sortDirection === "asc" ? comparison : -comparison;
    });
  }, [monitors, sortBy, sortDirection]);

  return (
    <Table.Root className="monitors-table" variant="surface">
      <Table.Header>
        <Table.Row>
          <Table.ColumnHeaderCell style={{ width: "60%" }}>
            <SortableColumnHeader
              label={
                <Flex align="center" ml="3">
                  Monitor
                </Flex>
              }
              sortKeys="prompt"
              sortBy={sortBy}
              sortDirection={sortDirection}
              onSortChange={handleSort}
            />
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell style={{ width: "15%" }}>
            <SortableColumnHeader
              label="Last run"
              sortKeys="recent_results_summary.detected_count"
              sortBy={sortBy}
              sortDirection={sortDirection}
              onSortChange={handleSort}
            />
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>
            <SortableColumnHeader
              label="Frequency"
              sortKeys="details.run_frequency"
              sortBy={sortBy}
              sortDirection={sortDirection}
              onSortChange={handleSort}
            />
          </Table.ColumnHeaderCell>
          <Table.ColumnHeaderCell>
            <SortableColumnHeader
              label="Created"
              sortKeys="created_at"
              sortBy={sortBy}
              sortDirection={sortDirection}
              onSortChange={handleSort}
            />
          </Table.ColumnHeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {sortedMonitors.map((monitor) => (
          <MonitorRow
            key={monitor.id}
            monitor={monitor}
            onRowClick={onRowClick}
          />
        ))}
      </Table.Body>
    </Table.Root>
  );
};

const EmptyState = ({
  title,
  description,
  icon: Icon,
  maxWidth = 256,
  children,
}) => (
  <Flex
    direction="column"
    align="center"
    justify="center"
    py="9"
    gap="5"
    style={{ height: "100%" }}
  >
    {Icon && (
      <Icon height={120} width={120} strokeWidth={1.25} color={gray.gray7} />
    )}
    <Flex
      direction="column"
      align="center"
      gap="1"
      mt="2"
      style={{ maxWidth: maxWidth, width: "100%" }}
    >
      <Text size="4" weight="medium" align="center">
        {title}
      </Text>
      <Text size="2" align="center" style={{ color: gray.gray9 }}>
        {description}
      </Text>
    </Flex>
    {children}
  </Flex>
);

const MonitorListContainer = ({ title, children }) => (
  <Flex className="monitor-list-container" direction="column">
    <Toolbar title={title} />
    <ScrollArea
      scrollbars="vertical"
      type="scroll"
      style={{ height: "calc(100vh - 80px)" }}
    >
      {children}
    </ScrollArea>
  </Flex>
);

const Loading = ({ currentTab }) => (
  <MonitorListContainer title={capitalize(currentTab)}>
    <Flex justify="center" align="center" style={{ height: "100%" }}>
      <Flex direction="column" align="center" gap="4">
        <Spinner size="3" style={{ transform: "scale(1.5)" }} />
        <Text size="2" weight="medium" style={{ color: gray.gray10 }}>
          Loading...
        </Text>
      </Flex>
    </Flex>
  </MonitorListContainer>
);

const NoMonitors = () => {
  const navigate = useNavigate();
  return (
    <Flex
      align="center"
      justify="center"
      style={{
        height: "calc(100% - 300px)",
        borderTop: `1px solid ${gray.gray6}`,
      }}
    >
      <EmptyState
        title="Create your first monitor"
        description="You don't have any monitors yet. When you create one, it will appear here."
        icon={Telescope}
        maxWidth={280}
      >
        <Button
          size="2"
          radius="large"
          variant="outline"
          onClick={() => navigate("/monitors/create")}
        >
          <Plus size={16} />
          New monitor
        </Button>
      </EmptyState>
    </Flex>
  );
};

function MonitorList() {
  const navigate = useNavigate();
  const location = useLocation();
  const { getMonitors } = useMonitor();
  const [monitorsData, setMonitorsData] = useState(null);

  const currentTab = location.pathname.split("/").pop();

  useEffect(() => {
    getMonitors().then(setMonitorsData);
  }, [getMonitors]);

  const filteredMonitors = useMemo(() => {
    if (!monitorsData) return [];
    // return monitorsData.filter(
    //   (monitor) => monitor.monitor_access === currentTab
    // );
    return monitorsData;
  }, [monitorsData, currentTab]);

  if (!monitorsData) return <Loading currentTab={currentTab} />;

  const hasMonitors = filteredMonitors.length > 0;

  const renderContent = () => {
    if (!hasMonitors) return <NoMonitors />;

    return filteredMonitors.length > 0 ? (
      <MonitorsTable
        monitors={filteredMonitors}
        onRowClick={(id) => navigate(`/monitors/${currentTab}/${id}`)}
      />
    ) : (
      <EmptyState
        title="No matching monitors found"
        description="Try adjusting your search"
        icon={SearchX}
      />
    );
  };

  return (
    <MonitorListContainer title={capitalize(currentTab)}>
      <IntroHeader />
      {renderContent()}
    </MonitorListContainer>
  );
}

export default MonitorList;
